const Company = {
    name: 'Bijoux Terner',
    email: '',
    phone: '',
    privacy: '',
    terms: '',
    store_id: 'bijouxterner',
    points_per_amount: 0.2
}

export default Company